<template>
  <div class="application">
    <!-- <div class="application-banner">
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/banner.fd76be7b.png" v-if="$isMobile()" />
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/banner.f8493f10.png" v-else>
      <div class="application-banner-title">
        <h3>VR专业介绍</h3>
      </div>
    </div> -->
    <div class="application-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: 'vr-major' })">VR专业介绍</span>
    </div>
    <div class="application-content">
      <div class="application-top" style="display: flex; align-items: center;gap: 20px;flex-wrap: wrap;">
        <div style="width: 240px;">
          <div style="display: flex; align-items: center;gap: 8px;">
            <div class="application-top-title">
              <svg width="80.000000" height="80.000000" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs />
                <path id="path"
                  d="M59.51 50.06C59.67 50.13 59.83 50.21 59.99 50.33L79.23 63.7L75.92 68.54L56.65 55.14C56.48 55.02 56.35 54.9 56.23 54.78C55.13 56.06 53.94 57.24 52.65 58.31C51.35 59.38 49.98 60.34 48.52 61.17C47.06 62.01 45.55 62.72 43.97 63.29C42.39 63.86 40.78 64.3 39.12 64.6C23.23 67.45 8.03 56.88 5.17 40.99C2.32 25.1 12.88 9.86 28.78 7C44.67 4.15 59.87 14.72 62.73 30.61C64.01 37.55 62.7 44.35 59.51 50.06ZM38.09 58.83C50.8 56.54 59.28 44.39 56.99 31.64C54.71 18.93 42.55 10.45 29.81 12.74C17.09 15.02 8.62 27.21 10.9 39.92C13.23 52.67 25.37 61.11 38.09 58.83Z"
                  fill="#B5B1B1" fill-opacity="1.000000" fill-rule="nonzero" />
              </svg>
              学校属性
            </div>
            <div class="application-top-select">
              <input :class="{ 'application-top-input': true, 'application-top-input--active': focus === 'select1' }"
                type="text" :value="schoolProp.dictLabel" placeholder="请选择学校属性" :readonly="true"
                @focus="change('select1', 'focus')" @blur="selectBlur()">
              <div class="select-content" :class="{ 'active': focus === 'select1' }">
                <div v-for="(item, index) in schoolPropList"
                  :class="{ 'select-line': true, 'select-line--active': schoolProp && (schoolProp.dictValue === item.dictValue) }"
                  @click="change(item, 'schoolProp')" :key="'select_' + index">
                  <p>{{ item.dictLabel }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 230px;">
          <div style="display: flex; align-items: center;gap: 8px;">
            <div class="application-top-title">
              省市
            </div>
            <div class="application-top-select">
              <input :class="{ 'application-top-input': true, 'application-top-input--active': focus === 'select2' }"
                type="text" :value="provinceCity.dictLabel" placeholder="请选择" :readonly="true"
                @focus="change('select2', 'focus')" @blur="selectBlur()">
              <div class="select-content" :class="{ 'active': focus === 'select2' }">
                <div v-for="(item, index) in provinceCityList"
                  :class="{ 'select-line': true, 'select-line--active': provinceCity && (provinceCity.dictValue === item.dictValue) }"
                  @click="change(item, 'provinceCity')" :key="'select_' + index">
                  <p>{{ item.dictLabel }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 250px;">
          <div style="display: flex; align-items: center;gap: 8px;">
            <div class="application-top-title">学校名称</div>
            <input :class="{ 'application-top-input': true, 'application-top-input--active': focus === 'input' }"
              type="text" :value="name" @input="event => change(event.target.value, 'name')" placeholder="输入学校名称"
              @focus="change('input', 'focus')" @blur="change('', 'focus')">
          </div>
        </div>
        <div style="width: 300px;">
          <div style="display: flex; align-items: center;gap: 8px;">
            <div class="application-top-title">学生层次</div>
            <div class="application-top-select">
              <input :class="{ 'application-top-input': true, 'application-top-input--active': focus === 'select3' }"
                type="text" :value="studentLevel.dictLabel" placeholder="请选择" :readonly="true"
                @focus="change('select3', 'focus')" @blur="selectBlur()">
              <div class="select-content" :class="{ 'active': focus === 'select3' }">
                <div v-for="(item, index) in studentLevelList"
                  :class="{ 'select-line': true, 'select-line--active': studentLevel && (studentLevel.dictValue === item.dictValue) }"
                  @click="change(item, 'studentLevel')" :key="'select2_' + index">
                  <p>{{ item.dictLabel }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height:20px;"></div>
      <div class="row" style="min-height: 212px;">
        <empty v-if="total === 0" :message="'暂无学校'" />
        <div v-else class="application-item col-sm-3 col-12" v-for="(item) in applications" :key="item.id">
          <vr-major-item :info="item"></vr-major-item>
        </div>
      </div>
      <page :total="total" @pageChange="getList"></page>
    </div>
  </div>
</template>
<script>
import vrMajorItem from './vr-major-item'
import page from '@components/page/page'
import empty from '@components/empty/empty'
export default {
  name: 'VRMajor',
  components: {
    vrMajorItem,
    page,
    empty
  },
  data() {
    return {
      schoolPropList: [],
      schoolProp: { dictLabel: "全部", dictValue: "全部" },
      studentLevelList: [],
      studentLevel: { dictLabel: "全部", dictValue: "全部" },
      provinceCityList: [],
      provinceCity: { dictLabel: "全部", dictValue: "全部" },
      name: '',
      focus: '',
      applications: [],
      total: 0,
    }
  },
  created() {
    this.getList(1)
    this.getTabs()
  },
  methods: {
    selectBlur() {
      setTimeout(() => {
        this.focus = ''
      }, 200);
    },
    change(item, type) {
      switch (type) {
        case "schoolProp":
          this.schoolProp = this.schoolProp.dictValue === item.dictValue ? { dictLabel: "全部", dictValue: "全部" } : item;
          this.getList(1)
          break;
        case "provinceCity":
          this.provinceCity = this.provinceCity.dictValue === item.dictValue ? { dictLabel: "全部", dictValue: "全部" } : item;
          this.getList(1)
          break;
        case "name": this.name = item;
          this.getList(1)
          break;
        case "studentLevel":
          this.studentLevel = this.studentLevel === item ? { dictLabel: "全部", dictValue: "全部" } : item;
          this.getList(1)
          break;
        case "focus": this.focus = item; break;
      }
    },
    getList(pageNum) {
      let url = 'intro/list'
      if (pageNum === 1) {
        this.applications = []
      }
      this.$http({
        url,
        params: {
          pageNum,
          pageSize: 8,
          schoolProp: this.schoolProp && this.schoolProp.dictValue !== "全部" ? this.schoolProp.dictValue : undefined,
          provinceCity: this.provinceCity && this.provinceCity.dictValue !== "全部" ? this.provinceCity.dictValue : undefined,
          schoolName: this.name || undefined,
          studentLevel: this.studentLevel && this.studentLevel.dictValue !== "全部" ? this.studentLevel.dictValue : undefined
        }
      }).then(res => {
        this.applications = res.rows || []
        this.total = res.total
      })
    },
    getTabs() {
      this.$http2({
        url: "/system/dict/data/type/school_prop",
      }).then(res => {
        this.schoolPropList = [{ dictLabel: "全部", dictValue: "全部" }, ...res.data]
      })
      this.$http2({
        url: "/system/dict/data/type/student_level",
      }).then(res => {
        this.studentLevelList = [{ dictLabel: "全部", dictValue: "全部" }, ...res.data]
      })
      this.$http2({
        url: "/system/dict/data/type/province_city",
      }).then(res => {
        this.provinceCityList = [{ dictLabel: "全部", dictValue: "全部" }, ...res.data]
      })
    }
  }
}
</script>
<style scoped lang="scss">
.application {
  position: relative;

  &-item {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        width: 400px;
        display: inline-block;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 30px 30px 30px;
    background: #fff;
    margin-bottom: 40px;
  }

  &-header {
    margin-top: 60px;
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-top {
    margin-top: 10px;

    &-title {
      flex-basis: 1;
      font-size: 16px;
      color: #9D999A;
      font-family: Microsoft YaHei;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-input {
      flex: 1;
      width: 100%;
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
      display: inline-block;
      font-family: Microsoft YaHei;
      outline: none;

      &::placeholder {
        font-size: 16px;
        font-family: Microsoft YaHei;
        letter-spacing: normal;
        padding: 0;
      }

      &--active {
        background: #2870b6;
        color: #fff;
        border: 0;
      }
    }

    &-select {
      flex: 1;
      position: relative;

      .select-content {
        position: absolute;
        width: 100%;
        top: 42px;
        left: 0;
        background-color: #F9F9F9;
        z-index: 999999;
        overflow: auto;
        display: none;

        &.active {
          display: block;
          animation: selectanim 0.25s;
          -webkit-animation: selectanim 0.25s;
        }

        .select-line {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 16px;
          font-size: 14px;
          line-height: 1;
          color: #666666;
          cursor: pointer;
          display: inline-block;
          font-family: Microsoft YaHei;
          outline: none;
          border-bottom: 1px dashed #BAD3E6;

          &--active {
            background: #2870b6;
            color: #fff;
            border: 0;
          }

          p {
            margin: 0;
          }

          &:last-child {
            border-bottom: 0;
          }
        }

        @keyframes selectanim {
          from {
            opacity: 0;
            top: 60px;
          }

          to {
            opacity: 1;
            top: 42px;
          }
        }

        @-webkit-keyframes selectanim {
          from {
            opacity: 0;
            top: 60px;
          }

          to {
            opacity: 1;
            top: 42px;
          }
        }
      }
    }
  }
}


::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>