<!--
 * @Author: your name
 * @Date: 2021-06-01 19:40:44
 * @LastEditTime: 2021-06-05 17:22:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\views\vrClass\vr-class-item.vue
-->
<template>
  <div class="vrClass-item" @click="toDetail">
    <div class="vrClass-img">
      <img :src="info.cover" />
    </div>
    <p class="l-1-overflow">{{ info.title }}</p>
  </div>
</template>
<script>
export default {
  name: "applicationItem",
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    toDetail() {
      let id = this.info.id
      this.$router.push({
        path: '/vr-major-detail',
        query: {
          id,
          url: encodeURIComponent(this.info.url || '')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.vrClass-item {
  width: 267px;
  height: 212px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
    width: 100%;
    padding: 15px 10px;
    box-sizing: border-box;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    line-height: 18px;
    text-align: center;
    font-style: normal;
    margin-bottom: 0;
  }

  h3 {
    font-size: 13px;
    font-weight: 600;
    color: #666666;
    line-height: 18px;
    padding: 10px;
  }
}

.vrClass-img {
  width: 267px;
  height: 160px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.vrClass-item:hover {
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2);
}

.vrClass-item-button {
  padding: 5px 10px;
  color: #999999;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid #eee;
  display: inline-block;
}

.vrClass-item-button:hover {
  border: 0;
  color: #fff;
  background: #2870b6;
}</style>